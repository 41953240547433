@mixin print-styles {
  @page {
    size: A4;
  }

  * {
    print-color-adjust: exact; 
  }


  .footer-wrapper,
  .MuiPageTitle-wrapper,
  .header-wrapper,
  .print-btn,
  .profile-header,
  .profile-personal-information {
    display: none !important;
  }
  .profile-personal-information.print {
    display: block !important;
  }
  .main-content-block {
    padding-top: 0 !important;
  }
  #watermark {
    display: block !important;
  }

  .page-two {
    page-break-before: always;
    background-image: url(../public/static/images/print-page/page2.jpg);
    background-position: bottom;
    background-size: cover;
    height: calc(100vh - 40px);
  }

  .page-two, .print-header {
    display: block !important;
  }

  .profile-item {
    -webkit-flex-basis: calc(100% / 4) !important;
    -ms-flex-preferred-size: calc(100% / 4) !important;
    flex-basis: calc(100% / 4) !important;
    padding-top: 3px !important;
  }
  
  .view-profile-chart-cont {
    -webkit-flex-basis: 50% !important;
    -ms-flex-preferred-size: 50% !important;
    flex-basis: 50% !important;
    max-width: 50% !important;
  }
  
  .chart-item-card-content {
    padding: 3px !important;
  }
  
  .chart-item-card-content div {
    font-size: 8px !important;
    margin-bottom: 0;
  }
  
  .chart-title {
    font-size: 20px !important;
  }
  
  .chart-item-name {
    font-size: 30px !important;
    opacity: 0.4 !important;
  }
  .css-t7asyr-MuiCardContent-root {
    padding: 12px !important;
  }
  
  .MuiPaper-root {
    box-shadow: none !important;
  }
  
  .MuiContainer-root {
    padding: 0 !important;
  }
  
  .conditions .MuiTypography-root {
    font-size: 14px !important;
  }
  
  .fill-form-item { float: left; width: 100%; overflow: hidden; white-space: nowrap }
  
  .fill-form-item:after { content: " .................................................................................." }

  .print-header h2  {
    font-family: tamil02;
    color: #ffff00;
  }
  .print-header .MuiTypography-h5 {
    font-family: tamil07;
    color: #ffff00;
  }
  
  .conditions .MuiTypography-subtitle1 {
    font-family: tamil02;
  }
  .conditions .MuiTypography-body2 {
    font-family: tamil05;
  }
  .profile-section-title.MuiTypography-root {
    font-family: tamil01;
  }
  .profile-item-container span.MuiTypography-caption {
    font-family: tamil02;
  }
  .profile-item-container div.MuiTypography-body1 {
    font-family: tamil09;
  }
  
  .print-header {
    background-image: url(../public/static/images/print-page/page1.jpg);
    background-position: top;
    background-size: cover;
  }
  
  .MuiPaper-root {
    background-color: transparent !important;
  }

  .view-profile-chart-cont td>div {
    background-color: transparent !important;;
  }

  #print-footer {
    padding-top: 140px;
    margin-top: -140px;
    background-image: url(../public/static/images/print-page/page1.jpg);
    background-position: bottom;
    background-size: cover;
    width: 100%;
  }

  .profile-identity .profile-section-title {
    text-align: center !important;
  }

  .profile-identity:nth-child(2) .MuiBox-root {
    flex-grow: 1;
  }
}

@media print {
  @include print-styles;
}

.image-download {
  @include print-styles;

  .image-container {
    width: 680px;
  }

  .page-one.hide {
    display: none !important;
  }
  .page-two.hide {
    display: none !important;
  }

  #watermark {
    position: absolute !important;
  }

  .page-two {
    height: auto;
    padding-bottom: 30px;
  }
}

.page-two, .print-header {
  display: none;
}

hr {
  margin: 10px 0;
  border-color: hsla(0, 0%, 93.7%, 0.47);
}

#watermark {
  display: none;
  position: fixed !important;
  top: 50%;
  left: 50%;
  height: fit-content;
  width: fit-content;
  z-index: 1000;
  font-size: 8rem;
  line-height: 7rem;
  transform: translate(-50%, -50%) rotate(-40deg);
  opacity: 0.1 !important;
  pointer-events: none;
}

.dummy-div {
  background-image: url(../public/static/images/print-page/page2.jpg), url(../public/static/images/print-page/page1.jpg);
}

.profile-personal-information.print {
  display: none;
}

/* ================================================================= */
